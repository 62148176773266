var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        justify: "center",
        value: _vm.value,
        persistent: "",
        "max-width": "400",
      },
    },
    [
      _c("v-card", { attrs: { color: "#fafafa" } }, [
        _c(
          "section",
          { staticClass: "ma-0 pa-6" },
          [
            _c(
              "v-row",
              { attrs: { justify: "center" } },
              [
                _c("v-icon", { attrs: { "x-large": "", color: "blue" } }, [
                  _vm._v("mdi-arrow-up-bold-box"),
                ]),
              ],
              1
            ),
            _c("v-row", { attrs: { justify: "center" } }, [
              _c("span", { staticClass: "pa-4" }, [
                _vm._v(" Post Selected Batch? "),
              ]),
              _c("span", { staticClass: "pa-4" }, [
                _vm._v(" Warning: This action cannot be undone. "),
              ]),
            ]),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "primary--text",
                        attrs: { text: "", depressed: "" },
                        on: { click: _vm.addNote },
                      },
                      [
                        _c("v-icon", { attrs: { left: "" } }, [
                          _vm._v("mdi-plus"),
                        ]),
                        _vm._v("Add Note "),
                      ],
                      1
                    ),
                    _vm.noteStatus
                      ? _c("v-text-field", {
                          attrs: {
                            label: "Optional Note",
                            rules: [
                              function (v) {
                                return (
                                  (v || "").length <= 2000 ||
                                  "Note must be 2000 characters or less"
                                )
                              },
                            ],
                            height: "80px",
                            outlined: "",
                            dense: "",
                          },
                          model: {
                            value: _vm.payload.note,
                            callback: function ($$v) {
                              _vm.$set(_vm.payload, "note", $$v)
                            },
                            expression: "payload.note",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-row",
              { staticClass: "pa-4", attrs: { justify: "center" } },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "elevation-1",
                    attrs: { height: "40" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.$emit("update:value", false)
                      },
                    },
                  },
                  [_vm._v(" No ")]
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "white--text ml-2",
                    attrs: { color: "#0c67a5", height: "40" },
                    on: { click: _vm.postBatches },
                  },
                  [_vm._v("Yes ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }